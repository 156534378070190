html {
  min-height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/public/img/background.png) no-repeat ;
  background-size: 15%;
  background-position: bottom right;
  background-color: rgb(87, 87, 94);
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 20rem;
  box-sizing: inherit;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  padding: 20px;
  margin-top: 60px;
  background-color: black;
}

form button, form h5{
  margin: 20px 0;
}
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]){
  border-color: red;
  box-shadow: none;
}

.sidebar {
  float: left;
  position: fixed;
  background-color: rgb(34, 34, 34);
  width: 180px;
  height: 100vh;
  z-index: 1;
}
.side-space{
  width: 100%;
  padding-top: 12px;
}

.side-list li{
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
}

.sidebar a{
  color:white
}

.side-list li:hover{
  background-color:rgb(62, 62, 66, 0.5)
}

.chat-input-holder{
position: absolute;
bottom: 0;
width: 89%;
padding-left: 230px;
padding-bottom: 5px;
}

.chat-input-textarea{
  background-color: rgb(62, 62, 66);
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgb(0,0,0,0.5);
  outline: none;
  padding: 12px;
  color: white;
  font-size: 1.25em;
  resize: none;
  overflow: hidden;
}

.txtarea{
  background-color: rgb(62, 62, 66);
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgb(0,0,0,0.5);
  outline: none;
  padding: 12px;
  color: white;
  font-size: 1.1em;
  resize: none;
}

textarea::-webkit-scrollbar{
  width: 0px;
}

.chat-log{
 text-align: left;
 margin-left: 180px;
 position: fixed;
 width: 100%;
 height: 100vh;
 overflow-y: scroll;
}

.chat-message{
  padding: 12px;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
}

.avatar{
  background-color: rgb(211 47 47) ;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

.avatar.gpt3{
  background: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 10px;
}


.message{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  color: white;
}

.message-format.gpt3{
  background-color: rgb(66, 66, 75) ;
  width: 100%;
}

.message-format{
  width: 100%;
}

.template {
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  background-color: rgba(255, 255, 255);
  border: 1px solid rgb(62, 62, 66, 0.5);
  border-radius: 12px;
  width: 280px;
  height: 250px;
}

.templates {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding-bottom: 160px;
  /* display: grid;
  grid-template-columns: auto auto auto auto;
  place-items: center; */
  padding-left: 180px;
}
.template-block {
  display: grid;
  grid-template-columns: auto auto auto auto;
  place-items: center;
}
.template-block-small {
  display: grid;
  grid-template-columns: auto auto auto;
  place-items: center;
}
.template-block-small h5 {
  height: 120px;
  width: 300px;
  display: grid;
  place-items: center;
  padding-bottom: 25px;
}

.plan-block {
  display: grid;
  grid-template-columns: auto auto auto;
  place-items: center;
  text-align: center;
}

.plan {
  margin-top: 20px;
  background-color: white;
  width: 450px;
  height: 500px;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  /* border: solid 2px rgb(53, 51, 51); */
  box-shadow: 5px 5px 5px 5px rgb(0,0,0,0.5);
}
.plan:hover {
  border: solid 2px rgb(211, 47, 47, 0.75);
  margin-top: 18px;
  padding-left: 13px;
  padding-right: 13px;
  height: 502px;
  
}

.plan-img {
  padding-top: 20px;
}

.purchase.p-basic {
  background: rgb(197 192 170);
  /* background: linear-gradient(90deg, rgba(74 145 158) 0%, rgba(235 172 162) 75%); */
  border-radius: 5px;
}
.purchase.p-pro {
  background: rgb(184, 170, 108);
  /* background: linear-gradient(90deg, rgba(235 172 162) 0%, rgba(206 106 107) 75%); */
  border-radius: 5px;
}
.purchase.p-premium {
  background: rgb(176 82 106);
  /* background: linear-gradient(90deg, rgba(206 106 107) 10%, rgb(138, 51, 189) 95%); */
  border-radius: 5px;
}
.purchase a {
  color: black;
}
.purchase.p-basic:hover {
  background: rgb(129, 124, 100);
  /* background: linear-gradient(90deg, rgba(55, 124, 136) 0%, rgb(214, 141, 129) 75%); */
  box-shadow: 2px 2px 2px 2px rgb(0,0,0,0.5);
}
.purchase.p-pro:hover {
  background: rgb(124, 113, 61);
  box-shadow: 2px 2px 2px 2px rgb(0,0,0,0.5);
}
.purchase.p-premium:hover {
  background: rgb(116, 51, 67);
  box-shadow: 2px 2px 2px 2px rgb(0,0,0,0.5);
}
.purchase {
  box-shadow: 2px 2px 2px 2px rgb(0,0,0,0.25);
}
.purchase:hover a {
  color: rgb(231, 229, 229);
}

.price {
height: 55px;
color: white;
border-radius: 25px;
vertical-align: middle;
box-shadow: 2px 2px 2px 2px rgb(0,0,0,0.25);
}

.price.basic {
  background-color: rgb(74 145 158);
}
.price.pro {
  background-color: rgb(235 172 162);
}
.price.premium {
  background-color: rgb(206 106 107);
}

.templates-title {
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.templates a{
  color: black;
}

.template:hover {
  border: 2px solid rgba(211, 47, 47, 0.75);
  box-shadow: 5px rgb(0,0,0,0.7);
  padding-left: 24px;
  padding-right: 24px;
  height: 251px;
  margin-top: 19px;
}

.template-img {
  width: 50px;
  height: 50px;
}

.template-img-home {
  padding-top: 20px;
  padding-bottom: 10px;
  height: 80px;
}

.sidebar-img {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 10px;
}

.template-input {
  float: left;
  position: fixed;
  padding-left: 205px;
  padding-top: 25px;
  padding-bottom: 100px;
  padding-right: 25px;
  background-color: rgb(62, 62, 66);
  height: 100vh;
  width: 50%;
  color: white;
  overflow-y: scroll;
}

.template-output {
  float: right;
  position: fixed;
  margin-left: 50%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 100px;
  background-color: rgb(66, 66, 75);
  color:white;
  width: 50%;
  height: 100vh;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(82, 79, 79, 0.75);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(44, 37, 37, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(44, 37, 37);
}
 .template-info {
  padding-left: 80px;
 }

 .template-input-field {
  padding-top: 25px;
 }

 .btn {
  background-color: rgb(229 57 53);
  z-index: 1;
 }

 .btn:hover {
  background-color: rgb(187, 14, 14);
 }

 .btn:focus {
  background-color: rgb(229 57 53);
 }

 .btn:focus:hover {
  background-color: rgb(187, 14, 14);
 }

 .btn-clear {
  background-color: rgb(62,62,66);
  z-index: 1;
  width: 125px;
  height: 30px;
  box-shadow: none;
  border: none;
  color: white;
  border-radius: 5px;
  border: 10px;
  border-color: white;
 }

 .btn-clear:hover {
  background-color: rgb(187, 14, 14);
 }

 .btn-clear:focus {
  background-color: rgb(62,62,66);
 }

 .btn-clear:focus:hover {
  background-color: rgb(187, 14, 14);
 }

 .template-output h5 {
  text-align: center;
 }

 .logo-img {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  width: 55px;
  height: 55px;
 }

 ul.ai-list {
  list-style-type: circle;
 }

.ai-response{
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: pre-wrap;
}

#nl.active {
  text-decoration: underline 2px #d32f2f;
  text-underline-offset: 10px;
}
#sidebar.active {
  text-decoration: underline 2px #d32f2f;
  text-underline-offset: 10px;
}

input[type=submit] {
  background:none;
  border:0 none;
  cursor:pointer;
  width: 100%;
}
input[type=submit]:hover {
  color: white
}
form.pay {
  background: none;
  height: 50px;
  margin-top: -10px;
}

.pay-block {
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  text-align: center;
}

.pay-return {
  color: green;
}

.pay-cancel {
  color: red;
}

.pay-img {
  height: 250px;
  margin-top: 100px;
}

.error-message{
  color: #d32f2f;
}
.success-message{
  color: green
}

.forgot-password{
  font-size: 85%;
}

.sign-up{
  font-size: 85%;
}

.dashboard-items {
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
}

.dashboard {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding-bottom: 160px;
  padding-left: 180px;
}

.dashboard-item {
  margin-top: 20px;
  padding: 12px;
  background-color: rgb(150, 150, 150);
  border: 1px solid rgb(62, 62, 66, 0.5);
  border-radius: 12px;
  width: 500px;
  height: 550px;
  color: white;
}

.token-usage{
  display: grid;
 place-items: center;
}

.news {
  width: 700px;
}

.chart {
  padding-top: 10px;
  width: 85%;
}

.footer{
  position: absolute;
  height: 200px;
  top: auto;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
  background-color:rgb(62, 62, 66, 0.5);
}


.footer-img {
  height: 25px;
  float: left;
  padding-right: 10px;

}

.footer-links li {
  padding-bottom: 5px;
  padding-top: 5px;
  width: 150px;
  padding-left: 10px;
}

.home {
  color: white;
}

.template-block-small li{
  height: 200px;
  width: 300px;
  background-color: #ffffff;
  color: black;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px rgb(0, 0, 0, 0.5); 
  }

.hero-content {
  min-height: 100vh;
  padding-left: 100px;
  padding-top: 100px;
  background-color: #050f1b;
}
.hero-content h1 {
  position: relative;
  z-index: 1;
}
.video {
  position: absolute;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.cta-button {
 margin-top: 80px;
}

.hero-content .cta-button {
  margin-top: 150px;
 }

#features {
  min-height: 100vh;
  padding-top: 20px;
  background-color: #050f1b;
}

#features ul {
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
}

#features li {
  height: 400px;
  width: 500px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px rgb(0, 0, 0);  
  margin-bottom: 25px;
}
#features li h5 {
  position: relative;
  z-index: 1;
  margin-right: 20px;
  margin-left: 20px;
  padding: 100px 20px 0 20px;
  z-index: 1;
}

.video-ai {
  position: absolute;
  height: 400px;
  width: 500px;
  object-fit: cover;
  border-radius: 20px;
  opacity: 70%;
  }

#templates {
  min-height: 100vh;
  padding-top: 20px;
  background-color: #050f1b;
}
#templates h5 {
  padding-bottom: 25px;
}

#pricing {
  padding-top: 20px;
  min-height: 70vh;
}

.return {
  color: #ffffff;
}

.img-return {
  padding-right: 10px;
  padding-top: 20px;
  margin-left: 230px;
  
}

.billing-block {
  background-color:#050f1b86 ;
  padding: 10px 12px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.backdrop-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-custom {
  width: clamp(50%, 700px, 90%);
  height: min(55%, 450px);
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  color: black;
}

.buttons button {
  margin-left: 5px;
  margin-right: 5px;
}

#cancel {
  background-color: #ffffff;
  color: #d32f2f;
  box-shadow: none;
}

#cancel:hover {
  color:#050f1b
}

.terms {
  overflow-y: scroll;
  width: clamp(50%, 700px, 90%);
  height: min(50%, 500px);
  margin-top: 150px;
}

.pointer:hover {
  cursor: pointer;
}

.terms-links li {
  text-decoration: white underline;
  text-underline-offset: 2px;
}

.policies {
  background-color: white;
  padding: 1px 25px 5px 25px;
  border-radius: 25px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.policies h2 {
  text-align: center;
}

.navbar-links {
  padding-right: 5%;
}

.verify {
  height: 500px;
  width: 50vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  border-radius: 50px;
  box-shadow: 3px 3px 3px 3px rgb(0, 0, 0);
  padding: 0px 20px 0px 20px; 
}

#verify-btn {
  margin-top: 20px;
}

.img-size-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.img-size-selection button{
  margin: 0px 10px 0px 10px;
}

.img-size-selection .btn{
  border-radius: 20px;
}
#image-ai {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#image-ai img{
  padding-bottom: 25px;
}

.promo{
  color: #ffffff;
  padding: 12px 0 0 25px;
}

@media screen and (max-width: 600px)
{
  h1{
    font-size: 36px ;
    line-height: 50px;
  }
  
    .hero-content {
      min-height: 100vh;
      padding-left: 20px;
      padding-top: 1px;
    }
    #features ul {
      display:flex;
      flex-direction: column;
    }
    .video-ai {
      position: absolute;
      height: 250px;
      width: 380px;
      }
      #features li {
        height: 250px;
        width: 380px; 
        margin-bottom: 15px;
      }
      #features li h5 {
        margin-right: auto;
        margin-left: auto;
        padding: 60px 20px 0 20px;
      }
      .template-block-small {
        display: flex;
        flex-direction: column;
      }
      .template-block-small h5 {
        height: 120px;
        width: 300px;
      }
      .template-block-small li {
        height: 150px;
        width: 300px;
        margin-bottom: 15px;
      }
      .footer{
        position:static;
        height: 200px;
        width: 100%;
        padding-top: 5px;
      }      
      .plan-block {
        display: flex;
        flex-direction: column;
      }
      .plan {
        width: 350px;
        height: 500px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .nav-logo {
        display: none;
      }
      .nav-logo ul {
        display: none;
      }
      .navbar-links {
        padding-right: 0%;
      }
      .sidebar {
      float: none;
      height: 100px;
      width: 100%;
      position: sticky;
      padding-top: 1px;
      overflow-x: scroll;
      }
      .sidebar-img {
        width: 20px;
        height: 20px;
        float: left;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .side-list li{
        padding-top: 10px;
        padding-bottom: 0px;
        padding-left: 40px;
        padding-right: 20px;
      }
      .side-grid ul{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      #sidebar.active {
        text-underline-offset: 5px;
      }
      .templates {
        position: static;
        height: 0;
        min-height: 100vh;
        padding-left: 0px;
        overflow-y: none;
        padding-bottom: 0;
      }
      #templates{
        padding-bottom: 50px;
      }
      .template-block {
        display: flex;
        flex-direction: column; 
      }
      .dashboard-items {
        display: flex;
        flex-direction: column;
      }
      .dashboard {
        position: static;
        overflow-y: scroll;
        width: 100%;
        height: unset;
        padding-bottom: 15px;
        padding-left: 0px;
      }
      .dashboard-item {
        margin-top: 20px;
        padding: 12px;
        width: 350px;
        height: 550px;
      }
      .modal-custom {
        height: min(50%, 410px);    
      }
      h2 {
        font-size: 30px ;
        line-height: 35px;
      }
      .template-input {
        float: none;
        position: static;
        padding-left: 15px;
        padding-right: 15px;
        height: unset;
        width: 100%;
        padding-bottom: 15px;
        overflow-y: none;
      }
      .template-output {
        float: none;
        position: static;
        margin-left: 0%;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 25px;
        padding-bottom: 15px;
        width: 100%;
        height: unset;
        min-height: 50vh;
      }
      h4 {
        font-size: 25px ;
        line-height: 30px;
        }
        .backdrop-custom {
          height: 100%;
        }
        .verify {
          height: 50vh;
          width: 85vw;
          margin-top: 20vh;
        }
}




